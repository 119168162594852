import { template as template_a60fb3eb07ed46ba8b6605e9451bfa77 } from "@ember/template-compiler";
const FKText = template_a60fb3eb07ed46ba8b6605e9451bfa77(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
