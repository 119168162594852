import { template as template_3ce2d0bc6c6c4d92957e4ac287667666 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3ce2d0bc6c6c4d92957e4ac287667666(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
