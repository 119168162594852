import { template as template_65f3654aa47748b7b1c0df60225076d4 } from "@ember/template-compiler";
const FKLabel = template_65f3654aa47748b7b1c0df60225076d4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
